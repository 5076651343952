import React from "react";

const ContentContainer = ({ children }) => {
  return (
    <div className="bg-progray-700 flex-1 rounded-4xl py-10 px-20 overflow-y-auto relative">
      {children}
    </div>
  );
};

export default ContentContainer;
