/**
 * Generate query parameters url: search=val&orderBy=val..etc
 *
 * @param {*} object
 * @returns
 */
export const generateUrlParameters = (object) => {
  const queryParams = [];

  for (const param in object) {
    if (object[param])
      queryParams.push(`${param}=${encodeURIComponent(object[param])}`);
  }

  if (queryParams.length > 0) return queryParams.join("&");

  return "";
};
