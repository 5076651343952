import React, { useEffect, useState } from "react";
import TopNav from "./../../components/TopNav";
import ContentContainer from "./../../components/Containers/ContentContainer";
import { SocialService } from "../../services";
import AddSocialForm from "../../components/Social/AddForm";
import Social from "../../components/Social";
import AddSubButton from "../../components/Social/AddSubButton";
import Modal from "../../components/Modal";
import Svg from "../../components/Svg";
import PrivacyForm from "../../components/Sites/PrivacyForm";
import SiteService from "../../services/SiteService";
import RecoveryForm from "../../components/Sites/RecoveryForm";
import TermsForm from "../../components/Sites/TermsForm";

const Setting = () => {
  const [sites, setSites] = useState({});
  const [socialHtmlElements, setSocialHtmlElements] = useState(null);
  const [socialIcon, setSocialIcon] = useState(null);
  const [socialUrl, setSocialUrl] = useState(null);
  const [socials, setSocials] = useState([]);
  const [showPrivacyForm, setShowPrivacyForm] = useState(false);
  const [showRecoveryForm, setShowRecoveryForm] = useState(false);
  const [showTermsForm, setShowTermsForm] = useState(false);
  const [socialLoader, setSocialLoader] = useState(false);

  useEffect(() => {
    const getSocials = async () => {
      try {
        const response = await SocialService.getSocials();
        setSocials(response);
      } catch (error) {
        console.log(error.message);
      }
    };

    getSocials();
  }, []);

  useEffect(() => {
    const getSites = async () => {
      const sitesInfo = await SiteService.getSites();
      setSites(sitesInfo);
    };

    getSites();
  }, [showPrivacyForm, showRecoveryForm, showTermsForm]);

  const handleAddSocialLinkClick = () => {
    const socialFormHtml = getSocialFormHtmlElement();
    setSocialHtmlElements(socialFormHtml);
  };

  const removeSocialHtmlElement = () => {
    setSocialHtmlElements(null);
    document.getElementById("selected-icon").classList.remove("w-16", "h-16");
  };

  const getSocialFormHtmlElement = () => {
    return (
      <AddSocialForm
        removeSocialHtmlElement={removeSocialHtmlElement}
        setSocialIcon={setSocialIcon}
        setSocialUrl={setSocialUrl}
      />
    );
  };

  const handleSubmit = async () => {
    try {
      setSocialLoader(true);
      await SocialService.saveSocial({
        icon: socialIcon,
        url: socialUrl,
      });

      removeSocialHtmlElement();

      const response = await SocialService.getSocials();

      setSocials(response);
      setSocialLoader(false);
    } catch (error) {
      setSocialLoader(false);
      console.log(error.message);
    }
  };

  const deleteSocial = async (socialId) => {
    try {
      await SocialService.deleteSocialById(socialId);

      const response = await SocialService.getSocials();
      setSocials(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <TopNav />

      <ContentContainer>
        <div className="h-full flex justify-between py-16">
          {/* Social Links */}
          <div className="flex-1">
            <h2 className="font-bold text-2xl">معلومات التواصل الاجتماعي</h2>
            {/* Socials list */}
            <div className="flex flex-col gap-y-4 ml-32 mt-10">
              {socials.map((social, index) => (
                <Social
                  index={index}
                  social={social}
                  removeSocialHtmlElement={removeSocialHtmlElement}
                  deleteSocial={() => deleteSocial(social.id)}
                />
              ))}
            </div>
            {/* Added social form */}
            <div id="social-link-form-container" className="flex ml-32">
              {socialHtmlElements}
            </div>
            {/* Add, Submit button */}
            <AddSubButton
              socialLoader={socialLoader}
              setSocialLoader={setSocialLoader}
              socialHtmlElements={socialHtmlElements}
              handleSubmit={handleSubmit}
              handleAddSocialLinkClick={handleAddSocialLinkClick}
            />
          </div>

          {/* Line in the middle */}
          <div className="h-full w-[2px] bg-progray-200"></div>

          {/* Pages */}
          <div className="flex-1 flex flex-col gap-y-10">
            <div className="flex items-center justify-between gap-x-10 w-10/12 mx-auto">
              <span className="text-xl">الشروط والأحكام</span>
              <button type="button" onClick={() => setShowTermsForm(true)}>
                <Svg name="pen" />
              </button>
            </div>
            <div className="flex items-center justify-between gap-x-10 w-10/12 mx-auto">
              <span className="text-xl">سياسة الخصوصية</span>
              <button type="button" onClick={() => setShowPrivacyForm(true)}>
                <Svg name="pen" />
              </button>
            </div>
            <div className="flex items-center justify-between gap-x-10 w-10/12 mx-auto">
              <span className="text-xl">سياسة الاسترجاع</span>
              <button type="button" onClick={() => setShowRecoveryForm(true)}>
                <Svg name="pen" />
              </button>
            </div>
          </div>
        </div>

        <Modal
          show={showPrivacyForm}
          setShow={setShowPrivacyForm}
          closeAction={() => setShowPrivacyForm(false)}
          heading={"تعديل سياسة الخصوصية"}
          className="w-[40%]"
        >
          <PrivacyForm
            sitesInfo={sites}
            show={showPrivacyForm}
            setShow={setShowPrivacyForm}
          />
        </Modal>
        <Modal
          show={showRecoveryForm}
          setShow={setShowRecoveryForm}
          closeAction={() => setShowRecoveryForm(false)}
          heading={"تعديل سياسة الاسترجاع"}
          className="w-[40%]"
        >
          <RecoveryForm
            sitesInfo={sites}
            show={showPrivacyForm}
            setShow={setShowRecoveryForm}
          />
        </Modal>
        <Modal
          show={showTermsForm}
          setShow={setShowTermsForm}
          closeAction={() => setShowTermsForm(false)}
          heading={"تعديل الشروط والأحكام"}
          className="w-[40%]"
        >
          <TermsForm
            sitesInfo={sites}
            show={showPrivacyForm}
            setShow={setShowTermsForm}
          />
        </Modal>
      </ContentContainer>
    </>
  );
};

export default Setting;
