import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ReservationService = {
  /**
   * Gets a list of reservations
   *
   * @param {*} queryParameters
   * @returns
   */
  getAllReservations: async (queryParameters = {}) => {
    const response = await axios.get(
      `${API_BASE_URL}/reservations${
        queryParameters.length > 0 ? `?${queryParameters}` : ""
      }`
    );
    return response.data;
  },

  /**
   * Gets a reservation by id
   *
   * @param {*} id integer the id of the reservation
   * @returns reservation
   */
  getReservationById: async (id) => {
    const response = await axios.get(`${API_BASE_URL}/reservations/${id}`);
    return response.data;
  },

  /**
   *
   */
  deleteReservation: async (id) => {
    const response = await axios.delete(`${API_BASE_URL}/reservations/${id}`);
    return response.data;
  },
};

axios.defaults.headers.common["Accept"] = "application/json";

export default ReservationService;
