import axios from "axios";
import UploadService from "./UploadService";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SocialService = {
  /**
   *
   * @param {*}
   * @returns
   */
  getSocials: async data => {
    const response = await axios.get(`${API_BASE_URL}/social-info`);
    return response.data;
  },

  /**
   *
   * @param {*}
   * @returns
   */
  saveSocial: async data => {
    try {
      const uploadResponse = await UploadService.uploadImage(data.icon);

      const response = await axios.post(`${API_BASE_URL}/social-info`, {
        url: data.url,
        icon: uploadResponse?.images[0],
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   *
   * @param {*}
   * @returns
   */
  deleteSocialById: async id => {
    return await axios.delete(`${API_BASE_URL}/social-info/${id}`);
  },
};

axios.defaults.headers.common["Accept"] = "application/json";

export default SocialService;
