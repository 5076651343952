import React, { useEffect, useState } from "react";
import TopNav from "./../../components/TopNav";
import ContentContainer from "./../../components/Containers/ContentContainer";
import Svg from "../../components/Svg";
import { NavLink } from "react-router-dom";
import { CouponService } from "../../services";
import { generateUrlParameters, getPublicUrl } from "../../utils";

const Coupon = () => {
  const [coupons, setCoupons] = useState([]);
  const [isCouponLoading, setIsCouponLoading] = useState(true);
  const [orderBy, setOrderBy] = useState("");
  const [orderType, setOrderType] = useState("");
  const [query, setQuery] = useState("");

  useEffect(() => {
    const getAllCoupons = async (queryParameters = {}) => {
      try {
        const response = await CouponService.getAllCoupons(queryParameters);
        if (queryParameters) setCoupons(response.coupons);
        else setCoupons(response);
        setIsCouponLoading(false);
      } catch (error) {
        if (process.env.REACT_APP_ENV === "development")
          console.error("Error fetching products:", error.message);
      }
    };

    getAllCoupons(
      generateUrlParameters({
        query,
        orderBy,
        orderType,
      })
    );
  }, [query, orderBy, orderType]);

  const handleDeleteCouponClick = async (id) => {
    try {
      setIsCouponLoading(true);
      await CouponService.deleteCoupon(id);
      const response = await CouponService.getAllCoupons();
      setCoupons(response);
      setIsCouponLoading(false);
    } catch (error) {
      console.log(error);
      setIsCouponLoading(false);
    }
  };

  const handleOrderByClick = (orderString) => {
    const [orderByFeild, orderTypeFeild] = orderString.split(":");

    setOrderBy(orderByFeild);
    setOrderType(orderTypeFeild);
  };

  return (
    <>
      <TopNav />

      <ContentContainer>
        {/* Filter and Search */}
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-x-4 border-b text-white">
            <Svg name="query" />
            <input
              type="text"
              placeholder="البحث ضمن الكوبونات"
              className="bg-progray-700 outline-none text-xl py-2"
              onInput={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="group">
              <div className="flex items-center gap-x-2 text-white text-xl">
                <Svg name="filter" />
                <span>فلترة</span>
              </div>
              <div className="z-10 absolute hidden group-hover:flex bg-progray-500 px-4 py-6 rounded-md text-xl w-60 top-0 left-0 flex-col gap-y-7 animate-fade-up">
                <div className="flex items-center gap-x-2 text-white text-xl">
                  <Svg name="filter" />
                  <span>فلترة</span>
                </div>
                <div
                  className="font-light cursor-pointer hover:opacity-75"
                  onClick={() => handleOrderByClick("name:ASC")}
                >
                  حسب الاسم (أ-ي)
                </div>
                <hr />
                <div
                  className="font-light cursor-pointer hover:opacity-75"
                  onClick={() => handleOrderByClick("name:DESC")}
                >
                  حسب الاسم (ي-أ)
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Table */}
        <table className="w-full mt-16 border-separate border-spacing-y-10">
          <thead>
            <tr>
              <th className="text-xl">الكوبون</th>
              <th className="text-xl">قيمة الحسم</th>
              <th className="text-xl">تاريخ البداية</th>
              <th className="text-xl">تاريخ الانتهاء</th>
              <th className="text-xl">مرات الاستخدام</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="6">
                <NavLink
                  to={"/coupon/create"}
                  className="cursor-pointer hover:opacity-80 transition-opacity w-full border rounded-lg flex items-center justify-center py-4"
                >
                  <Svg name="plus" />
                </NavLink>
              </td>
            </tr>
            {!isCouponLoading ? (
              <>
                {coupons.length > 0 ? (
                  coupons.map((coupon, index) => (
                    <tr key={index}>
                      <td className="bg-progray-500 py-8 text-center mt-10 rounded-tr-lg rounded-br-lg">
                        {coupon.name}
                      </td>
                      <td className="bg-progray-500 py-8 text-center mt-10">
                        {coupon.discount}
                      </td>
                      <td className="bg-progray-500 py-8 text-center mt-10">
                        {coupon.startDate.split("T")[0]}
                      </td>
                      <td className="bg-progray-500 py-8 text-center mt-10">
                        {coupon.endDate.split("T")[0]}
                      </td>
                      <td className="bg-progray-500 py-8 text-center mt-10">
                        {coupon.usedTimes}
                      </td>
                      <td className="bg-progray-500 py-8 text-center mt-10 rounded-tl-lg rounded-bl-lg">
                        <div
                          className="cursor-pointer"
                          onClick={() => handleDeleteCouponClick(coupon.id)}
                        >
                          <Svg name={"trash"} color={"#FF5454"} />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div>لم يتم العثور على اي كوبون, قم بإضافة كوبون</div>
                )}
              </>
            ) : (
              <>
                <tr className="animate-pulse">
                  <td
                    colSpan="6"
                    className="bg-progray-500 py-11 text-center mt-10 rounded-lg w-full"
                  ></td>
                </tr>
                <tr className="animate-pulse">
                  <td
                    colSpan="6"
                    className="bg-progray-500 py-11 text-center mt-10 rounded-lg w-full"
                  ></td>
                </tr>
                <tr className="animate-pulse">
                  <td
                    colSpan="6"
                    className="bg-progray-500 py-11 text-center mt-10 rounded-lg w-full"
                  ></td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </ContentContainer>
    </>
  );
};

export default Coupon;
