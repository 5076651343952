import { getPublicUrl } from "./../../utils/filestream";
import NavigationLink from "./NavigationLink";
import Svg from "../Svg";

const Sidebar = () => {
  return (
    <div className="w-[10.1%] rounded-4xl bg-progray-700 h-full py-10 flex flex-col justify-between items-center">
      {/* LOGO */}
      <div>
        <img src={"/images/go-pro-logo.png"} alt="" />
      </div>
      <div className="flex flex-col gap-y-6">
        <NavigationLink to="/product">
          <Svg name={"cart"} />
          المتجر
        </NavigationLink>
        <NavigationLink to="/category">
          <Svg name={"stack"} />
          الخدمات
        </NavigationLink>
        <NavigationLink to="/setting">
          <Svg name={"settings"} />
          إعدادات
        </NavigationLink>
        <NavigationLink to="/reservation">
          <Svg name={"calendar"} />
          الحجوزات
        </NavigationLink>
        <NavigationLink to="/coupon">
          <Svg name={"coupon"} />
          الكوبونات
        </NavigationLink>
        {/* <NavigationLink to="/profile">
          <div className="w-9 h-9 rounded-full bg-white"></div>
          Admin
        </NavigationLink> */}
      </div>
      <div></div>
    </div>
  );
};

export default Sidebar;
