import React from "react";
import Svg from "../Svg";

const AddSocialForm = ({
  removeSocialHtmlElement,
  setSocialIcon,
  setSocialUrl,
}) => {
  const handleSelectedSocialIcon = event => {
    const file = event.target.files[0];
    if (file) {
      setSocialIcon(file);

      const imageURL = URL.createObjectURL(file);

      let imgElement = document.createElement("img");

      imgElement.src = imageURL;
      imgElement.classList.add("object-cover");

      let imageContainer = document.getElementById("selected-icon");
      imageContainer.classList.add("w-16", "h-16");
      imageContainer.innerHTML = "";
      imageContainer.appendChild(imgElement);
    }
  };

  return (
    <div className="flex items-center gap-x-8 w-full mt-10">
      <div></div>
      <div>
        <label
          htmlFor="upload-social-icon"
          className="flex gap-x-4 items-center cursor-pointer"
        >
          <Svg name="upload" />
          <div className={"text-xl underline"} id="selected-icon">
            رفع الأيقونة
          </div>
        </label>
        <input
          type="file"
          className="sr-only"
          id="upload-social-icon"
          onChange={e => handleSelectedSocialIcon(e)}
        />
      </div>
      <div className="flex-1">
        <input
          type="text"
          className="bg-progray-700 py-1 border-b border-progray-200 w-full text-xl outline-none"
          placeholder="الرابط"
          onInput={e => setSocialUrl(e.target.value)}
        />
      </div>
      <button type="button" onClick={removeSocialHtmlElement}>
        <Svg name="trash" />
      </button>
    </div>
  );
};

export default AddSocialForm;
