import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const NotificationService = {
  /**
   * Gets a list of categories
   *
   * @param {*} queryParameters
   * @returns
   */
  getAllNotifications: async (queryParameters = {}) => {
    const response = await axios.get(
      `${API_BASE_URL}/notifications${
        queryParameters.length > 0 ? `/store?${queryParameters}` : ""
      }`
    );
    return response.data;
  },
};

axios.defaults.headers.common["Accept"] = "application/json";

export default NotificationService;
