import Svg from "../Svg";

const Modal = ({
  show,
  closeAction,
  heading,
  children,
  className = "w-[30%]",
}) => {
  return show ? (
    <div className="overflow-y-auto">
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50"></div>
      <div
        className={
          "absolute top-10 -translate-x-1/2 bg-black px-12 py-10 rounded-4xl" +
          " " +
          className
        }
      >
        {/* Heading */}
        <div className="flex justify-between">
          <h2 className="text-xl">{heading}</h2>
          <button type="button" onClick={closeAction}>
            <Svg name="close-sq" />
          </button>
        </div>
        {/* Body */}
        <div className="min-h-[200px] mt-10">{children}</div>
        {/* Footer */}
        <div></div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Modal;
