import React from "react";
import { NavLink } from "react-router-dom";

const NavigationLink = ({ to, children }) => {
  return (
    <NavLink
      to={to}
      className="flex gap-x-4 text-white items-center hover:bg-progray-500 py-4 px-6 rounded-xl transition-colors"
    >
      {children}
    </NavLink>
  );
};

export default NavigationLink;
