import React, { useEffect, useState } from "react";
import TopNav from "./../../components/TopNav";
import ContentContainer from "./../../components/Containers/ContentContainer";
import { useForm } from "react-hook-form";
import { CouponService } from "../../services";
import SubmitButton from "./../../components/Button/SubmitButton";
import { NavLink, useNavigate } from "react-router-dom";

const CreateCoupon = () => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleOnSubmit = async (data) => {
    try {
      setSubmitLoader(true);
      await CouponService.createCoupon(data);
      navigate("/coupon");
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      console.log(error.message);
    }
  };
  return (
    <>
      <TopNav />

      <ContentContainer>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="flex flex-col h-full justify-between py-10"
        >
          <h2 className="font-bold text-2xl">إضافة الكوبون</h2>
          <div className="min-h-[4/6] flex justify-between py-16 relative">
            <div className="flex-1 flex flex-col gap-y-16">
              <div className="flex flex-col gap-y-4">
                <div className="flex items-center gap-x-10">
                  <label htmlFor="" className="text-xl w-2/12">
                    الاسم
                  </label>
                  <input
                    type="text"
                    className="outline-none text-2xl border-b-2 border-progray-200 bg-progray-700"
                    {...register("name", { required: true })}
                  />
                </div>
                {errors.name && (
                  <p className="text-red-500 text-xl">يرجى إدخال الحقل</p>
                )}
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex items-center gap-x-10">
                  <label htmlFor="" className="text-xl w-2/12">
                    تاريخ البداية
                  </label>
                  <input
                    type="date"
                    className="outline-none text-2xl border-b-2 border-progray-200 bg-progray-700"
                    {...register("startDate", { required: true })}
                  />
                </div>
                {errors.startDate && (
                  <p className="text-red-500 text-xl">يرجى إدخال الحقل</p>
                )}
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex items-center gap-x-10">
                  <label htmlFor="" className="text-xl w-2/12">
                    تاريخ الانتهاء
                  </label>
                  <input
                    type="date"
                    className="outline-none text-2xl border-b-2 border-progray-200 bg-progray-700"
                    {...register("endDate", { required: true })}
                  />
                </div>
                {errors.endDate && (
                  <p className="text-red-500 text-xl">يرجى إدخال الحقل</p>
                )}
              </div>
            </div>
            <div className="absolute top-14 bottom-0 left-1/2 -translate-x-1/2 w-[2px] bg-progray-200"></div>
            <div className="flex-1 flex flex-col gap-y-10">
              <div className="flex flex-col gap-y-4 mr-32">
                <div className="flex items-center gap-x-10 ">
                  <label htmlFor="" className="text-xl w-2/12">
                    قيمة الحسم
                  </label>
                  <div className="border-b-2 border-progray-200 flex justify-between items-center">
                    <input
                      type="text"
                      className="bg-progray-700 outline-none text-2xl"
                      {...register("discount", { required: true })}
                    />
                    <div className="text-3xl">%</div>
                  </div>
                </div>
                {errors.name && (
                  <p className="text-red-500 text-xl">يرجى إدخال الحقل</p>
                )}
              </div>
              <div className="flex flex-col gap-y-4 mr-32">
                {/* <div className="flex items-center gap-x-10 ">
                  <label htmlFor="" className="text-xl w-2/12">
                    مرات الاستخدام
                  </label>
                  <input
                    type="text"
                    className="outline-none text-2xl border-b-2 border-progray-200 bg-progray-700 w-16 text-center"
                    {...register("usedTimes", { required: false })}
                  />
                </div> */}
                {/* {errors.name && (
                  <p className="text-red-500 text-xl">يرجى إدخال الحقل</p>
                )} */}
              </div>
            </div>
          </div>
          <div className="h-1/6">
            <div className="flex gap-x-5 items-end justify-center">
              <SubmitButton loading={submitLoader} size="small">
                إضافة
              </SubmitButton>
              <NavLink
                to="/coupon"
                className="border-2 border-[#8B8B8B] text-xl py-3 w-[10vw] font-bold rounded-lg text-center"
              >
                إلغاء
              </NavLink>
            </div>
          </div>
        </form>
      </ContentContainer>
    </>
  );
};

export default CreateCoupon;
