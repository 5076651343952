import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

class AuthService {
  async login(username, password) {
    const response = await axios.post(`${API_BASE_URL}/auth/login`, {
      username,
      password,
    });
    return response.data;
  }

  logout() {
    localStorage.removeItem("token");
  }

  getToken() {
    return localStorage.getItem("token");
  }
}

export default new AuthService();
