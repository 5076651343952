import { useForm } from "react-hook-form";
import SubmitButton from "../Button/SubmitButton";
import SiteService from "../../services/SiteService";
import { useEffect, useState } from "react";

const RecoveryForm = ({ sitesInfo, show, setShow }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [recoveryPolicy, setRecoveryPolicy] = useState("");
  const [recoveryPolicyAr, setRecoveryPolicyAr] = useState("");
  const [formsLoader, setFormsLoader] = useState(false);

  useEffect(() => {
    if (sitesInfo) {
      setRecoveryPolicy(sitesInfo.recoveryPolicy ?? "");
      setRecoveryPolicyAr(sitesInfo.recoveryPolicyAr ?? "");
    }

    reset(sitesInfo);
  }, [sitesInfo, reset]);

  const handleOnSubmit = async (data) => {
    try {
      setFormsLoader(true);
      const response = await SiteService.saveSiteInfo(data);
      setShow(false);
      setFormsLoader(false);
    } catch (error) {
      console.log(error.message);
      setFormsLoader(false);
    }
  };

  return (
    <form
      className="flex flex-col gap-y-10"
      onSubmit={handleSubmit(handleOnSubmit)}
    >
      <div className="flex flex-col gap-y-2">
        <label>الأنكليزية</label>
        <textarea
          className="bg-black outline-none border-b border-progray-200 resize-none"
          rows={4}
          {...register("recoveryPolicy")}
          defaultValue={recoveryPolicy}
        ></textarea>
      </div>
      <div className="flex flex-col gap-y-2">
        <label htmlFor="">العربية</label>
        <textarea
          className="bg-black outline-none border-b border-progray-200 resize-none"
          rows={4}
          {...register("recoveryPolicyAr")}
          defaultValue={recoveryPolicyAr}
        ></textarea>
      </div>
      <div className="flex w-full justify-center">
        <SubmitButton loading={formsLoader} disabled={false} size={"small"}>
          حفظ
        </SubmitButton>
      </div>
    </form>
  );
};

export default RecoveryForm;
