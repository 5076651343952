import React, { useState } from "react";
import Toast from "../Toast";

const MainContainer = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToastWithMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };

  return (
    <div className="relative flex-1 flex flex-col gap-y-10 text-white">
      {children}
      {showToast && <Toast message={toastMessage} />}
    </div>
  );
};

export default MainContainer;
