import { getPublicUrl } from "../../utils";
import Svg from "../Svg";

const InlineImages = ({
  defaultValue,
  selectedImages,
  setSelectedImages,
  files,
  setFiles,
}) => {
  const handleImagesChange = (event) => {
    const file = event.target.files[0];

    const updatedFiles = [...files];
    updatedFiles.push(file);

    setFiles(updatedFiles);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const updatedImages = [...selectedImages];
        updatedImages.push(reader.result);

        setSelectedImages(updatedImages);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex items-center gap-x-10">
      {defaultValue && (
        <div
          className="flex items-center gap-x-10"
          id="product-images-container"
        >
          {defaultValue?.map((image, index) => (
            <div className="w-20 h-20 rounded-full">
              <img
                src={`${getPublicUrl()}/${image}`}
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
          ))}
        </div>
      )}
      <div className="flex items-center gap-x-10" id="product-images-container">
        {selectedImages.map((image, index) => (
          <div className="w-20 h-20 rounded-full">
            <img src={image} className="w-full h-full object-cover" alt="" />
          </div>
        ))}
      </div>
      <label
        htmlFor="product-images-input"
        className="cursor-pointer w-20 h-20 border-progray-200 border-2 rounded-full flex items-center justify-center"
      >
        <Svg name="plus" />
        <input
          id="product-images-input"
          type="file"
          multiple
          className="sr-only"
          onChange={handleImagesChange}
        />
      </label>
    </div>
  );
};

export default InlineImages;
