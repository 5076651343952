import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { getPublicUrl } from "./../../utils";
import { AuthService } from "./../../services";
import Svg from "../../components/Svg";

function Login(props) {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [responseError, setResponseError] = useState(undefined);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleOnSubmit = async (data) => {
    try {
      const { access_token } = await AuthService.login(
        data.username,
        data.password
      );

      signIn({
        token: access_token,
        tokenType: "Bearer",
        expiresIn: 43200,
        authState: {
          username: data.username,
        },
      });

      navigate("/product");
    } catch (error) {
      if (error?.response?.data?.message === "Unauthorized")
        setResponseError(true);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="w-full h-screen overflow-hidden bg-car-showcase bg-cover bg-center flex justify-center text-white">
      <div className="h-full w-[30%] flex flex-col justify-around items-center py-20">
        <img
          className="h-[10.6%]"
          src={getPublicUrl() + "/images/go-pro-logo.png"}
          alt=""
        />
        <h1 className="text-4xl font-bold text-white">لوحة تحكم المشرف</h1>
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          className="w-full bg-progray-500 flex flex-col px-16 py-10 justify-between gap-y-10 rounded-4xl"
        >
          <h2 className="text-2xl text-center font-bold">تسجيل الدخول</h2>
          <div>
            <div className="flex gap-x-4 justify-between items-end">
              <label className="text-xl w-4/12" htmlFor="">
                البريد الالكتروني
              </label>
              <div className="border-b py-5 flex-1">
                <input
                  type="text"
                  className="bg-progray-500 outline-none w-full"
                  {...register("username", { required: true })}
                />
              </div>
            </div>
            {errors.password && (
              <p className="text-red-500 mt-4 text-lg">يرجى إدخال الحقل</p>
            )}
          </div>
          <div>
            <div className="flex gap-x-4 justify-between items-end">
              <label className="text-xl w-4/12" htmlFor="">
                كلمة المرور
              </label>
              <div className="border-b py-5 flex justify-between items-center flex-1">
                <input
                  type={showPassword ? "text" : "password"}
                  className="bg-progray-500 outline-none w-full"
                  {...register("password", { required: true })}
                />
                <div onClick={togglePasswordVisibility}>
                  {showPassword ? <Svg name="eye-off" /> : <Svg name="eye" />}
                </div>
              </div>
            </div>
            {errors.password && (
              <p className="text-red-500 mt-4 text-lg">يرجى إدخال الحقل</p>
            )}
          </div>
          <div>
            <div className="flex gap-x-5">
              <input type="checkbox" {...register("rememberMe")} />
              <label htmlFor="">تذكرني</label>
            </div>
            {responseError && (
              <p className="text-red-500 mt-4 text-lg">
                المعلومات المدخلة غير صحيحة
              </p>
            )}
          </div>

          <button className="text-2xl flex justify-center py-3 text-black bg-white w-full rounded-full font-bold">
            تسجيل الدخول
          </button>
        </form>
      </div>
    </div>
  );
}

export default Login;
