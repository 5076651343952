import axios from "axios";
import UploadService from "./UploadService";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SiteService = {
  /**
   * Gets all sites data
   *
   * @param {*} data
   * @returns
   */
  getSites: async () => {
    const response = await axios.get(`${API_BASE_URL}/site-info`);

    // This is fixed id that contains the sites info based on the backend api
    const sitesId = 1;

    const sitesInfo = response.data.find((site) => site.id === sitesId);

    return sitesInfo;
  },

  /**
   * Saves all sites info
   *
   * @param {*}
   * @returns
   */
  saveSiteInfo: async (data) => {
    let response;

    // This API is just for checking if the sites are exist or not
    // IF yes use the put method ELSE post
    const sitesResponse = await axios.get(`${API_BASE_URL}/site-info`);

    if (
      sitesResponse?.data &&
      Array.isArray(sitesResponse?.data) &&
      sitesResponse.data.length > 0
    ) {
      response = await axios.put(`${API_BASE_URL}/site-info`, data);
    } else {
      response = await axios.post(`${API_BASE_URL}/site-info`, data);
    }

    return response.data;
  },
};

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] = `Bearer ${
  localStorage.getItem("_auth") ?? ""
}`;

export default SiteService;
