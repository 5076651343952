import React, { useState } from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";

const ReservationCalendar = ({ reservations }) => {
  const currentMonth = moment().month();
  const [selectedDate, setSelectedDate] = useState(moment());
  const arabicMonths = [
    "يناير",
    "فبراير",
    "مارس",
    "إبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "أكتوبر",
    "نوفمبر",
    "ديسمبر",
  ];
  const isReserved = (date) => {
    return reservations.some((reservation) =>
      moment(reservation.visitDay).isSame(date, "day")
    );
  };

  const getReservationByVisitDay = (date) => {
    const reservation = reservations.filter((reservation) =>
      moment(reservation.visitDay).isSame(date, "day")
    );

    return reservation;
  };

  const handleYearChange = (newYear) => {
    setSelectedDate(selectedDate.clone().year(newYear));
  };

  const handleMonthChange = (e, newMonth) => {
    const elements = document.querySelectorAll(".month-btn");

    elements.forEach((element) => {
      element.style.backgroundColor = "unset";
      element.style.color = "white";
    });

    e.target.style.backgroundColor = "white";
    e.target.style.color = "#111111";

    setSelectedDate(selectedDate.clone().month(newMonth));
  };

  const getStatusColor = (date) => {
    const reservedDate = reservations.find((reservation) =>
      moment(reservation.visitDay).isSame(date, "day")
    );

    return reservedDate
      ? reservedDate.status === "pending"
        ? "red"
        : "green"
      : "";
  };

  const renderCalendar = () => {
    const firstDayOfMonth = selectedDate
      .clone()
      .startOf("month")
      .startOf("week");
    const lastDayOfMonth = selectedDate.clone().endOf("month").endOf("week");

    const calendarRows = [];
    let currentDate = firstDayOfMonth.clone();

    while (currentDate.isBefore(lastDayOfMonth, "day")) {
      const calendarRow = [];

      for (let i = 0; i < 7; i++) {
        const isCurrentMonth = currentDate.isSame(selectedDate, "month");
        const isSelected = selectedDate.isSame(currentDate, "day");
        const isReservedDate = isReserved(currentDate);
        const reservation = getReservationByVisitDay(currentDate);
        const statusColor = getStatusColor(currentDate);

        calendarRow.push(
          <NavLink
            to={
              reservation?.length > 0
                ? "/reservation/" +
                  reservation[0].id +
                  "?date=" +
                  reservation[0].visitDay
                : "#"
            }
            key={i}
            className={`cursor-pointer flex flex-col items-center justify-center w-full aspect-square rounded-[10px] py-4 text-white border ${
              isCurrentMonth ? "border-gray-300" : "border-transparent"
            } ${isSelected ? "" : ""} ${
              isReservedDate
                ? statusColor === "green"
                  ? `bg-green-500`
                  : `bg-red-500`
                : ""
            }`}
          >
            <div>{currentDate.date()}</div>
            <div className="">
              {reservation?.length > 0 ? reservation.length + " حجوزات" : ""}
            </div>
          </NavLink>
        );

        currentDate.add(1, "day");
      }

      calendarRows.push(
        <div key={calendarRows.length} className="grid grid-cols-7 gap-x-10">
          {calendarRow}
        </div>
      );
    }

    return calendarRows;
  };

  return (
    <div className="flex flex-col items-center gap-y-10">
      <div className="mb-4">
        <div className="flex gap-x-2">
          <button
            className="text-white"
            onClick={() => handleYearChange(selectedDate.year() - 1)}
          >
            -
          </button>
          <h2 className="text-xl text-white">{selectedDate.format("YYYY")}</h2>
          <button
            className="text-white"
            onClick={() => handleYearChange(selectedDate.year() + 1)}
          >
            +
          </button>
        </div>
      </div>

      <div className="flex text-white justify-between w-8/12 mx-auto border rounded-lg overflow-hidden cursor-pointer">
        {moment.months().map((month, index) => (
          <div
            className={
              "month-btn flex items-center justify-center w-full p-2 " +
              (moment.months().length !== index + 1 ? "border-l" : "") +
              (currentMonth === index ? " bg-white text-black" : "")
            }
            data-index={index}
            key={index}
            onClick={(e) => handleMonthChange(e, index)}
          >
            {arabicMonths[index]}
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-y-1 w-full">
        <div className="w-8/12 mx-auto grid grid-cols-7 gap-2">
          {[
            "الأحد",
            "الاثنين",
            "الثلاثاء",
            "الأربعاء",
            "الخميس",
            "الجمعة",
            "السبت",
          ].map((day, index) => (
            <div
              key={index}
              className="text-center font-semibold text-white py-1"
            >
              {day}
            </div>
          ))}
        </div>

        <div className="w-8/12 mx-auto flex flex-col gap-y-6 mt-4">
          {renderCalendar()}
        </div>
      </div>
    </div>
  );
};

export default ReservationCalendar;
