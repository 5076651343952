import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CouponService = {
  /**
   * Gets a list of categories
   *
   * @param {*} queryParameters
   * @returns
   */
  getAllCoupons: async (queryParameters = {}) => {
    const response = await axios.get(
      `${API_BASE_URL}/coupon${
        queryParameters.length > 0 ? `/store?${queryParameters}` : ""
      }`
    );
    return response.data;
  },

  /**
   * Creates a coupon
   *
   * @param {*} dto
   * @return
   */
  createCoupon: async (data) => {
    const response = await axios.post(`${API_BASE_URL}/coupon`, {
      ...data,
    });

    return response.data;
  },

  /**
   *
   */
  deleteCoupon: async (id) => {
    const response = await axios.delete(`${API_BASE_URL}/coupon/${id}`);
    return response.data;
  },
};

axios.defaults.headers.common["Accept"] = "application/json";

export default CouponService;
