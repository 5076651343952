import { useForm } from "react-hook-form";
import Svg from "../Svg";

const Input = ({
  type = "text",
  placeholder = "",
  icon,
  backgroundColor = "bg-progray-700",
  iconPosition = "start",
  register,
  defaultValue = "",
}) => {
  return (
    <div
      className={
        "w-full flex items-center gap-x-2 border-b-2 border-progray-200 py-2 "
      }
    >
      {icon && iconPosition === "start" && <Svg name={icon} />}
      <div className="flex-1">
        <input
          type={type}
          placeholder={placeholder}
          className={"w-full outline-none text-xl " + backgroundColor}
          {...register}
          defaultValue={defaultValue}
        />
      </div>
      {icon && iconPosition === "end" && <Svg name={icon} />}
    </div>
  );
};

export default Input;
