/**
 * Gets the public folder url
 *
 * @returns string
 */
export const getPublicUrl = () => {
  return process.env.REACT_APP_PUBLIC_URL;
};

/**
 * Gets the api url
 *
 * @returns string
 */
export const getApiBaseUrl = () => {
  return process.env.REACT_APP_API_BASE_URL;
};
