import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const UploadService = {
  /**
   *
   *
   * @param {*} queryParameters
   * @returns
   */
  uploadImage: async image => {
    const formData = new FormData();
    formData.append("images", image);

    const response = await axios.post(
      `${API_BASE_URL}/upload-images`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      }
    );

    return response.data;
  },
};

axios.defaults.headers.common["Accept"] = "application/json";

export default UploadService;
