import React, { useEffect, useState } from "react";
import TopNav from "./../../components/TopNav";
import ContentContainer from "./../../components/Containers/ContentContainer";
import ReservationCalendar from "./../../components/Calendar";
import { ReservationService } from "./../../services";

const Reservation = () => {
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const getAllReservations = async () => {
      try {
        const reservationsList = await ReservationService.getAllReservations();
        setReservations(reservationsList);
      } catch (error) {
        console.log("Error: " + error.message);
      }
    };

    getAllReservations();
  }, []);

  return (
    <>
      <TopNav />

      <ContentContainer>
        <ReservationCalendar reservations={reservations} />
      </ContentContainer>
    </>
  );
};

export default Reservation;
