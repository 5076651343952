import TopNav from "../../components/TopNav";
import ContentContainer from "../../components/Containers/ContentContainer";
import Svg from "../../components/Svg";
import Modal from "./../../components/Modal/index";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import SubmitButton from "../../components/Button/SubmitButton";
import { ProductService } from "../../services";
import { getPublicUrl } from "../../utils";
import Toast from "../../components/Toast";

const CreateProduct = () => {
  const [activeVariant, setActiveVariant] = useState(-1);
  const [showPropertyContainer, setShowPropertyContainer] = useState("text");
  const [variants, setVariants] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    const getProductById = async () => {
      try {
        const product = await ProductService.getProductById(id);

        setProduct(product);
        reset(product);
        setVariants(product.variants ?? []);
      } catch (error) {
        console.log(error);
      }
    };

    getProductById();
  }, [id, reset]);

  const handleAddVariantClick = () => {
    const variant = {
      arName: "",
      enName: "",
      type: "text",
      isEnabled: true,
      subvariants: [],
    };

    const updatedVariants = [...variants];
    updatedVariants.push(variant);
    setActiveVariant(updatedVariants.length - 1);

    setVariants(updatedVariants);
  };

  const handleAddSubVariant = () => {
    addSubVariants({
      value: "",
      pricePlusOnSmallCar: 0,
      pricePlusOnBigCar: 0,
    });
  };

  const addSubVariants = (value) => {
    const updatedVariants = [...variants];
    updatedVariants[activeVariant].subvariants.push(value);

    setVariants(updatedVariants);
  };

  const removeSubVariant = (index) => {
    setVariants((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[activeVariant].subvariants.splice(index, 1);
      return updatedVariants;
    });
  };

  const handleTypeChange = (type) => {
    const updatedVariants = [...variants];
    updatedVariants[activeVariant].type = type;

    setShowPropertyContainer(type);
    setVariants(updatedVariants);
  };

  const handleSubVariantValueChange = (index, key, value) => {
    const updatedVariants = [...variants];
    updatedVariants[activeVariant].subvariants[index][key] = value;

    setVariants(updatedVariants);
  };

  const confirmAddedVariant = () => {
    setActiveVariant(-1);
  };

  const cancelAddedVariant = () => {
    const updatedVariants = [...variants];
    updatedVariants.splice(activeVariant, 1);

    setVariants(updatedVariants);
    setActiveVariant(-1);
  };

  const handleVariantArChange = (value) => {
    const updatedVariants = [...variants];
    updatedVariants[activeVariant].arName = value;

    setVariants(updatedVariants);
  };

  const handleVariantEnChange = (value) => {
    const updatedVariants = [...variants];
    updatedVariants[activeVariant].enName = value;

    setVariants(updatedVariants);
  };

  const handleImagesChange = (event) => {
    const file = event.target.files[0];

    const updatedFiles = [...files];
    updatedFiles.push(file);

    setFiles(updatedFiles);

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const updatedImages = [...selectedImages];
        updatedImages.push(reader.result);

        product.images = [...updatedImages];

        const newProduct = { ...product };
        setProduct(newProduct);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageDelete = (event, index) => {
    let updatedFiles = [];

    for (let i = 0; i < product.images.length; i++) {
      if (i != index) updatedFiles.push(product.images[i]);
    }

    product.images = [...updatedFiles];

    const newProduct = { ...product };
    setProduct(newProduct);
  };

  const handleFormSubmit = async (data) => {
    setSubmitLoader(true);

    data.variants = variants;
    data.images = product.images ?? [];

    try {
      if (!id) {
        const response = await ProductService.createProduct(data, files);
        navigate("/product");
      } else {
        const response = await ProductService.updateProduct(id, data, files);
      }
      showToastWithMessage("تمت حفظ التعديلات");
      setSubmitLoader(false);
    } catch (error) {
      setSubmitLoader(false);
      console.log(error);
    }
  };

  const showToastWithMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };

  const handleDeleteProductClick = async () => {
    try {
      await ProductService.deleteProduct(id);
      navigate("/product");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TopNav>
        {id && (
          <div
            onClick={handleDeleteProductClick}
            className="cursor-pointer flex gap-x-2 items-center text-red-500"
          >
            <Svg name={"trash"} color="rgb(239 68 68)" />
            <span>حذف المنتج</span>
          </div>
        )}
      </TopNav>

      <ContentContainer>
        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className="flex flex-col justify-between h-full"
        >
          <div className="flex gap-x-4">
            {/* RIGHT SIDE */}
            <div className="w-[65%] flex flex-col gap-y-4">
              <h2 className="text-2xl font-bold">تفاصيل المنتج</h2>
              <div className="mt-10 flex flex-col gap-y-8">
                {/* IMAGES */}
                <div className="flex items-center gap-x-10">
                  <div className="text-xl">صور</div>
                  <div className="flex items-center gap-x-10">
                    {product.images?.map((image, index) => (
                      <div className="w-20 h-20 rounded-full relative group">
                        {image?.includes("data") ? (
                          <img
                            src={`${image}`}
                            className="w-full h-full object-cover"
                            alt=""
                          />
                        ) : (
                          <img
                            src={`${getPublicUrl()}/${image}`}
                            className="w-full h-full object-cover"
                            alt=""
                          />
                        )}

                        <div
                          className="rounded-md absolute top-0 left-0 right-0 bottom-0 bg-gray-700 flex items-center justify-center text-4xl opacity-0 group-hover:opacity-100 cursor-pointer"
                          onClick={(e) => handleImageDelete(e, index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-trash3-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                          </svg>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center gap-x-10">
                    {selectedImages.map((image, index) => (
                      <div className="w-20 h-20 rounded-full">
                        <img
                          src={image}
                          className="w-full h-full object-cover"
                          alt=""
                        />
                      </div>
                    ))}
                  </div>
                  {product?.images?.length < 1 ? (
                    <label
                      htmlFor="product-images-input"
                      className="cursor-pointer w-20 h-20 border-progray-200 border-2 rounded-full flex items-center justify-center"
                    >
                      <Svg name="plus" />
                      <input
                        id="product-images-input"
                        type="file"
                        multiple
                        className="sr-only"
                        onChange={handleImagesChange}
                      />
                    </label>
                  ) : (
                    <></>
                  )}
                </div>
                <hr className="my-10 border-progray-500" />
                {/* NAME & DESCRIPTION */}
                <div className="flex items-center gap-x-10">
                  <div className="text-xl">الأسم</div>
                  <input
                    placeholder="العربية"
                    className="py-2 px-2 bg-progray-700 border-b-2 border-progray-200 text-xl outline-none"
                    defaultValue={product.arName ?? ""}
                    {...register("arName", { required: true })}
                  />
                  <input
                    placeholder="الإنكليزية"
                    className="py-2 px-2 bg-progray-700 border-b-2 border-progray-200 text-xl outline-none"
                    defaultValue={product.enName ?? ""}
                    {...register("enName", { required: true })}
                  />
                </div>
                {(errors.enName || errors.arName) && (
                  <p className="text-red-500 mt-4 text-lg">يجب إدخال الاسم</p>
                )}
                <div className="flex items-center gap-x-10 mt-10">
                  <div className="text-xl">الشرح</div>
                  <textarea
                    rows={3}
                    placeholder="العربية"
                    className="py-2 px-2 bg-progray-700 border-b-2 border-progray-200 text-xl size outline-none"
                    defaultValue={product.arDescription ?? ""}
                    {...register("arDescription", {
                      required: true,
                    })}
                  />
                  <textarea
                    rows={3}
                    placeholder="الإنكليزية"
                    className="py-2 px-2 bg-progray-700 border-b-2 border-progray-200 text-xl size outline-none"
                    defaultValue={product.enDescription ?? ""}
                    {...register("enDescription", {
                      required: true,
                    })}
                  />
                </div>
                {(errors.enDescription || errors.enDescription) && (
                  <p className="text-red-500 mt-4 text-lg">يجب إدخال الشرح</p>
                )}

                <hr className="my-10 border-progray-500" />
                {/* PRICES */}
                <div className="flex items-center gap-x-10">
                  <div className="text-xl w-[25%]">السعر للسيارة الكبيرة </div>
                  <input
                    placeholder="0"
                    className="py-2 px-2 bg-progray-700 border-b-2 border-progray-200 text-xl outline-none"
                    defaultValue={product.bigCarPrice}
                    {...register("bigCarPrice", { required: true })}
                  />
                </div>
                {(errors.enName || errors.arName) && (
                  <p className="text-red-500 mt-4 text-lg">
                    يجب إدخال هذا الحقل
                  </p>
                )}
                <div className="flex items-center gap-x-10">
                  <div className="text-xl w-[25%]">السعر للسيارة الصغيرة</div>
                  <input
                    placeholder="0"
                    className="py-2 px-2 bg-progray-700 border-b-2 border-progray-200 text-xl outline-none"
                    defaultValue={product.smallCarPrice}
                    {...register("smallCarPrice", { required: true })}
                  />
                </div>
                {(errors.enName || errors.arName) && (
                  <p className="text-red-500 mt-4 text-lg">
                    يجب إدخال هذا الحقل
                  </p>
                )}
              </div>
              <div className="text-xl"></div>
            </div>
            {/* LEFT SIDE */}
            <div className="w-[35%] flex flex-col gap-y-10">
              <h2 className="text-2xl font-bold">التفاصيل المطلوبة</h2>
              <div
                onClick={handleAddVariantClick}
                className="flex items-center justify-center gap-x-4 border-2 border-progray-200 rounded-xl py-2"
              >
                <Svg name="plus" />
                <div className="text-xl">التفاصيل المطلوبة</div>
              </div>

              {variants.map((item, index) => (
                <div className="flex gap-x-5 items-center justify-between text-xl w-2/3 mx-auto">
                  <div className="flex gap-x-2 items-center">
                    <input type="checkbox" className="mt-[2px]" />
                    <label>{item.arName}</label>
                  </div>
                  <div
                    className="text-progray-200 underline cursor-pointer"
                    onClick={() => setActiveVariant(index)}
                  >
                    تعديل
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-10 pb-10 flex justify-end w-full">
            <SubmitButton loading={submitLoader}>حفظ</SubmitButton>
          </div>
        </form>

        {/* Add attribute modal */}
        <Modal
          show={activeVariant > -1 ? true : false}
          heading={"إضافة الخيار"}
          closeAction={() => setActiveVariant(-1)}
          className="w-[40%]"
        >
          <div className="text-xl">
            <div>
              <label>الأسم</label>
              <div className="flex gap-x-4 mt-4">
                <input
                  className="w-1/2 outline-none border-b-2 border-progray-200 bg-black"
                  placeholder="العربية"
                  onInput={(e) => handleVariantArChange(e.target.value)}
                  value={variants[activeVariant]?.arName}
                />
                <input
                  className="w-1/2 outline-none border-b-2 border-progray-200 bg-black"
                  placeholder="الأنكليزية"
                  onInput={(e) => handleVariantEnChange(e.target.value)}
                  value={variants[activeVariant]?.enName}
                />
              </div>
            </div>
            <div className="mt-8">
              <h3>الخيارات</h3>
              <div>
                <div className="cursor-pointer mt-3 flex w-fit border rounded-xl border-progray-200 overflow-hidden">
                  <span
                    onClick={() => handleTypeChange("color")}
                    id="color-switch"
                    className={
                      "py-1 px-5 border-l border-progray-200 " +
                      (showPropertyContainer === "color"
                        ? "bg-white text-black"
                        : "")
                    }
                  >
                    لون
                  </span>
                  <span
                    onClick={() => handleTypeChange("text")}
                    className={
                      "py-1 px-5 " +
                      (showPropertyContainer === "text"
                        ? "bg-white text-black"
                        : "")
                    }
                  >
                    نص
                  </span>
                </div>
              </div>
              {variants[activeVariant]?.subvariants.map((item, index) => (
                <div className="mt-4 flex gap-x-2" key={index}>
                  <div className="w-1/2">
                    <input
                      type="text"
                      placeholder={
                        "قيمة " +
                        (showPropertyContainer === "color"
                          ? "لون #000000"
                          : "نص")
                      }
                      className="w-full outline-none bg-black border-b-2 border-progray-200"
                      value={item.value}
                      onInput={(e) =>
                        handleSubVariantValueChange(
                          index,
                          "value",
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className="flex flex-col gap-y-2 w-1/2">
                    <div className="border-b-2 border-progray-200 flex items-center gap-x-4">
                      <Svg name="small-car" />
                      <input
                        placeholder="0.00"
                        className="w-full outline-none bg-black "
                        value={item.pricePlusOnSmallCar}
                        onInput={(e) =>
                          handleSubVariantValueChange(
                            index,
                            "pricePlusOnSmallCar",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="border-b-2 border-progray-200 flex items-center gap-x-4">
                      <Svg name="big-car" />
                      <input
                        placeholder="0.00"
                        className="w-full outline-none bg-black"
                        value={item.pricePlusOnBigCar}
                        onInput={(e) =>
                          handleSubVariantValueChange(
                            index,
                            "pricePlusOnBigCar",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-8 flex items-center justify-center w-16 h-16 rounded-full bg-progray-700">
              <button type="button" onClick={handleAddSubVariant}>
                <Svg name="plus" />
              </button>
            </div>
            <div className="flex justify-center gap-x-3 items-center mt-10">
              <button
                type="button"
                className="py-2 w-2/3 bg-prored-500 text-center rounded-lg"
                onClick={confirmAddedVariant}
              >
                حفظ
              </button>
              <button
                type="button"
                className="py-2 w-2/3 border-prored-500 border text-center rounded-lg"
                onClick={cancelAddedVariant}
              >
                حذف
              </button>
            </div>
          </div>
        </Modal>
      </ContentContainer>

      {showToast && <Toast message={toastMessage} />}
    </>
  );
};

export default CreateProduct;
