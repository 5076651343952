import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthProvider, RequireAuth } from "react-auth-kit";

import Sidebar from "./components/Sidebar";
import MainContainer from "./components/Containers/MainContainer";
import Product from "./Pages/Product";
import Category from "./Pages/Category";
import CreateCategory from "./Pages/Category/Create";
import Reservation from "./Pages/Reservation";
import ShowReservation from "./Pages/Reservation/Show";
import Login from "./Pages/Auth/Login";
import Setting from "./Pages/Setting";
import Coupon from "./Pages/Coupon";
import CreateCoupon from "./Pages/Coupon/Create";
import CreateProduct from "./Pages/Product/Create";
import Notification from "./Pages/Notification";

function App() {
  return (
    <AuthProvider authType={"localstorage"} authName={"_auth"}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <RequireAuth loginPath={"/login"}>
                <AuthenticatedRoutes />
              </RequireAuth>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

function AuthenticatedRoutes() {
  return (
    <div className="flex h-screen min-h-screen p-10 bg-progray-500 gap-x-16">
      <Sidebar />
      <MainContainer>
        <Routes>
          <Route path="/product" element={<Product />} />
          <Route path="/product/create/:id" element={<CreateProduct />} />
          <Route path="/product/create" element={<CreateProduct />} />
          <Route path="/reservation/:id" element={<ShowReservation />} />
          <Route path="/reservation" element={<Reservation />} />
          <Route path="/category/:id" element={<CreateCategory />} />
          <Route path="/category/create" element={<CreateCategory />} />
          <Route path="/category" element={<Category />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/coupon/create" element={<CreateCoupon />} />
          <Route path="/coupon" element={<Coupon />} />
          <Route path="/notification" element={<Notification />} />
        </Routes>
      </MainContainer>
    </div>
  );
}

export default App;
