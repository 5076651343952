const Toast = ({ message }) => {
  return (
    <div
      id="add-cart-popup"
      class="absolute top-2 left-3 p-4 max-sm:shadow-md z-[100000]"
    >
      <div class="bg-green-400 py-3 px-10 rounded text-white flex items-center gap-x-2">
        <svg
          class="h-5 w-5 text-green-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          ></path>
        </svg>
        {message}
      </div>
    </div>
  );
};

export default Toast;
