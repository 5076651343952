import { useEffect, useState } from "react";
import ContentContainer from "../../components/Containers/ContentContainer";
import TopNav from "../../components/TopNav";
import NotificationService from "../../services/NotificationService";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);

  function formatDate(date) {
    const options = { day: "numeric", month: "long" };
    return date.toLocaleDateString("ar-EG", options);
  }

  useEffect(() => {
    const getAllNotifications = async () => {
      try {
        const response = await NotificationService.getAllNotifications();
        const groupedData = {};

        response.forEach((item) => {
          const timestamp = new Date(item.createdAt);
          const day = timestamp.getDate();
          const month = timestamp.getMonth();

          const key = `${formatDate(timestamp)}`;

          if (!groupedData[key]) {
            groupedData[key] = [];
          }

          groupedData[key].push(item.name);
        });

        setNotifications(groupedData);
      } catch (error) {
        console.log(error.message);
      }
    };

    getAllNotifications();
  }, []);
  return (
    <>
      <TopNav />

      <ContentContainer>
        <div className="flex flex-col gap-y-10">
          {Object.keys(notifications).map((key) => (
            <div key={key} className="flex flex-col gap-y-4">
              <h4 className="text-lg">{key}</h4>
              {notifications[key].map((notification, key) => (
                <div
                  className="py-5 px-10 flex bg-progray-500 rounded-[11px] text-lg"
                  key={key}
                >
                  {notification}
                </div>
              ))}
            </div>
          ))}
        </div>
      </ContentContainer>
    </>
  );
};

export default Notification;
