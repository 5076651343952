import axios from "axios";
import UploadService from "./UploadService";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ProductService = {
  /**
   * Gets a list of products
   *
   * @param {*} queryParameters
   * @returns
   */
  getAllProducts: async (queryParameters = {}) => {
    const response = await axios.get(
      `${API_BASE_URL}/products/store${
        queryParameters.length > 0 ? `?${queryParameters}` : ""
      }`
    );
    return response.data;
  },

  /**
   * Gets a product by id
   *
   * @param {*} id
   * @returns
   */
  getProductById: async (id) => {
    const response = await axios.get(`${API_BASE_URL}/products/${id}`);

    return response.data;
  },

  /**
   * Creates a product
   *
   * @param data main data with variants
   * @param files array of images
   * @returns
   */
  createProduct: async (data, files) => {
    let images = [];

    for (const image of files) {
      const uploadedImage = await UploadService.uploadImage(image);
      images.push(uploadedImage.images[0]);
    }

    data.images = images;

    const response = await axios.post(
      `${API_BASE_URL}/products/variants`,
      data
    );

    return response.data;
  },

  /**
   *
   */
  updateProduct: async (id, data, files) => {
    let images = [];

    for (const image of files) {
      const uploadedImage = await UploadService.uploadImage(image);
      images.push(uploadedImage.images[0]);
      break;
    }

    data.images = [...images];

    const response = await axios.put(
      `${API_BASE_URL}/products/variants/${id}`,
      data
    );

    return response.data;
  },

  deleteProduct: async (id) => {
    const response = await axios.delete(`${API_BASE_URL}/products/${id}`);

    return response;
  },
};

axios.defaults.headers.common["Accept"] = "application/json";

export default ProductService;
