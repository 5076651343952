import React, { useEffect, useState } from "react";
import TopNav from "./../../components/TopNav";
import ContentContainer from "./../../components/Containers/ContentContainer";
import ProductService from "./../../services/ProductService";
import { useForm } from "react-hook-form";
import Select from "react-select";
import Input from "../../components/Input";
import Textarea from "../../components/Input/Textarea";
import InlineImages from "../../components/Input/InlineImages";
import SubmitButton from "../../components/Button/SubmitButton";
import CategoryService from "../../services/CategoryService";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../../components/Toast";
import Svg from "../../components/Svg";

const CreateCategory = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [category, setCategory] = useState(null);
  const [files, setFiles] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const response = await ProductService.getAllProducts();

        let productsOpts = [];

        for (const product of response.products) {
          productsOpts.push({
            value: product.id,
            label: product.arName,
          });
        }

        setProductsOptions(productsOpts);
      } catch (error) {
        console.log(error);
      }
    };

    getAllProducts();

    const getCategoryById = async () => {
      try {
        const response = await CategoryService.getCategoryById(id);

        let selectedOpts = [];

        for (const product of response.products) {
          selectedOpts.push({
            value: product.id,
            label: product.arName,
          });
        }
        console.log(response);
        setSelectedOptions(selectedOpts);
        setCategory(response);
        reset(category);
      } catch (error) {
        console.log(error);
      }
    };

    if (id) getCategoryById(id);
  }, [id, reset]);

  const handleOnSubmit = async (data) => {
    let productIds = selectedOptions.map((option) => option.value);
    data.products = productIds;
    data.images = category?.images ?? [];
    setSubmitLoader(true);
    try {
      if (!id) {
        const response = await CategoryService.createCategory(data, files);

        navigate("/category");
        setSubmitLoader(false);
      } else {
        const response = await CategoryService.updateCategory(id, data, files);
        showToastWithMessage("تمت حفظ التعديلات");
        setSubmitLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showToastWithMessage = (message) => {
    setToastMessage(message);
    setShowToast(true);

    setTimeout(() => {
      setShowToast(false);
    }, 2000);
  };

  const handleDeleteCategoryClick = async () => {
    try {
      await CategoryService.deleteCategory(id);
      navigate("/category");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TopNav>
        {id && (
          <div
            onClick={handleDeleteCategoryClick}
            className="cursor-pointer flex gap-x-2 items-center text-red-500"
          >
            <Svg name={"trash"} color="rgb(239 68 68)" />
            <span>حذف الخدمة</span>
          </div>
        )}
      </TopNav>

      <ContentContainer>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <h2 className="mt-5 text-2xl font-bold">إضافة خدمة</h2>
          <div className="flex flex-col gap-y-16 mt-20">
            <div className="flex items-center gap-x-10 w-full">
              <label className="text-xl">الأسم</label>
              <Input
                type="text"
                register={{ ...register("arName") }}
                placeholder="العربية"
                defaultValue={category?.arName ?? ""}
              />
              <Input
                type="text"
                register={{ ...register("enName") }}
                placeholder="الأنكليزية"
                defaultValue={category?.enName ?? ""}
              />
            </div>

            <div className="flex items-center gap-x-10">
              <label className="text-xl">الشرح</label>
              <Textarea
                type="text"
                register={{ ...register("arDescription") }}
                placeholder="العربية"
                defaultValue={category?.arDescription ?? ""}
              />
              <Textarea
                type="text"
                register={{ ...register("enDescription") }}
                placeholder="الأنكليزية"
                defaultValue={category?.enDescription ?? ""}
              />
            </div>

            <div className="flex items-center gap-x-10">
              <div className="text-xl">صور</div>
              <InlineImages
                defaultValue={category?.images}
                selectedImages={selectedImages}
                setSelectedImages={setSelectedImages}
                files={files}
                setFiles={setFiles}
              />
            </div>

            <Select
              className="my-react-select-container"
              classNamePrefix="my-react-select"
              value={selectedOptions}
              isMulti={true}
              options={productsOptions}
              onChange={setSelectedOptions}
            />

            <div className="flex justify-end">
              <SubmitButton loading={submitLoader} size={"small"}>
                حفظ
              </SubmitButton>
            </div>
          </div>
        </form>
      </ContentContainer>

      {showToast && <Toast message={toastMessage} />}
    </>
  );
};

export default CreateCategory;
