import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import TopNav from "./../../components/TopNav";
import ContentContainer from "./../../components/Containers/ContentContainer";
import Svg from "../../components/Svg";
import { CategoryService } from "../../services";
import {
  generateUrlParameters,
  getApiBaseUrl,
  getPublicUrl,
} from "../../utils";

const Category = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getAllCategories = async () => {
      try {
        const response = await CategoryService.getAllCategories();
        setCategories(response);
      } catch (error) {
        if (process.env.REACT_APP_ENV === "development")
          console.error("Error fetching products:", error.message);
      }
    };

    getAllCategories();
  }, []);

  return (
    <>
      <TopNav />

      <ContentContainer>
        <div className="grid grid-cols-3 gap-y-20 py-20">
          <div className="flex items-center justify-center w-full">
            <NavLink
              to={"/category/create"}
              className="cursor-pointer hover:opacity-80 transition-opacity flex items-center justify-center gap-x-4 w-2/3 bg-progray-700 text-center rounded-lg py-6 text-xl border-2 border-progray-200"
            >
              <Svg name="plus" />
              إضافة خدمة
            </NavLink>
          </div>
          {categories.map((category, index) => (
            <NavLink
              to={"/category/" + category.id}
              className="cursor-pointer flex items-center justify-center w-full"
            >
              <div className="flex items-center justify-center gap-x-4 w-2/3 bg-progray-700 text-center rounded-lg py-7 text-xl shadow-pro">
                <span>{category.arName}</span>
              </div>
            </NavLink>
          ))}
        </div>
      </ContentContainer>
    </>
  );
};

export default Category;
