import React, { useEffect, useState } from "react";
import TopNav from "./../../components/TopNav";
import ContentContainer from "./../../components/Containers/ContentContainer";
import { ReservationService } from "./../../services";
import { useParams, useSearchParams } from "react-router-dom";
import Svg from "../../components/Svg";
import { useNavigate } from "react-router-dom";

const ShowReservation = () => {
  const [reservation, setReservation] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeReservation, setActiveReservation] = useState(0);

  useEffect(() => {
    const getAllReservations = async () => {
      try {
        const date = searchParams.get("date");
        const queryParameters = `date=${date}`;
        const response = await ReservationService.getAllReservations(
          queryParameters
        );

        setReservation(response);
      } catch (error) {
        console.log("Error: " + error.message);
      }
    };

    getAllReservations();
  }, [id]);

  const convertDate = date => {
    const arabicDays = [
      "السبت",
      "الأحد",
      "الاثنين",
      "الثلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
    ];

    const assyrianMonthsArabic = [
      "كانون الثاني",
      "شباط",
      "آذار",
      "نيسان",
      "أيار",
      "حزيران",
      "تموز",
      "آب",
      "أيلول",
      "تشرين الأول",
      "تشرين الثاني",
      "كانون الأول",
    ];

    const inputDate = new Date(date);
    const dayOfWeek = inputDate.getDay();
    const arabicDay = arabicDays[dayOfWeek];
    const arabicMonth = assyrianMonthsArabic[inputDate.getMonth()];
    const arabicYear = inputDate.getFullYear();

    const formattedDate = `${arabicDay} ${inputDate.getDate()} ${arabicMonth} ${arabicYear}`;
    return formattedDate;
  };

  const handleDeleteReservationClick = async id => {
    try {
      const res = await ReservationService.deleteReservation(id);
      navigate("/reservation");
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = status => {
    if (!status && !["created", "authorized", "closed"].includes(status))
      return "created";

    const statuses = {
      created: {
        text: "معلق",
        icon: "block",
        color: "text-[#FF1010]",
      },
      authorized: {
        text: "قيد المعالجة",
        icon: "block",
        color: "text-yellow-500",
      },
      closed: {
        text: "نشط",
        icon: "check",
        color: "text-green-500",
      },
    };

    return statuses[status];
  };

  return (
    <>
      <TopNav>
        <div className="text-xl flex gap-x-16">
          {reservation[activeReservation] &&
            reservation[activeReservation]?.order && (
              <div
                className={
                  getStatus(reservation[activeReservation]?.order?.status)?.color + " text-[#FF1010] flex items-center gap-x-4"
                }
              >
                <Svg
                  name={
                    getStatus(reservation[activeReservation]?.order?.status)?.icon
                  }
                />
                <span className="mb-1">
                  {getStatus(reservation[activeReservation]?.order?.status)?.text}
                </span>
              </div>
            )}
          <div
            className="cursor-pointer flex items-center gap-x-4"
            onClick={() =>
              handleDeleteReservationClick(reservation[activeReservation]?.id)
            }
          >
            <Svg name="trash" />
            <p>حذف الحجز</p>
          </div>
        </div>
      </TopNav>

      <ContentContainer>
        <div className="h-full flex flex-col gap-y-4">
          <div className="h-fit flex items-center gap-x-16 pt-10">
            <div className="flex items-center gap-x-8">
              <div
                className="cursor-pointer"
                onClick={() => navigate("/reservation")}
              >
                <Svg name="back" />
              </div>
              <h2 className="font-bold text-xl">
                {convertDate(reservation.length > 0 && reservation[0]?.visitDay)}
              </h2>
            </div>
            <h3 className="w-auto overflow-x-auto text-xl font-bold flex items-center gap-x-10 underline">
              {reservation.map((reserve, index) => (
                <span
                  className={
                    (index === activeReservation
                      ? "text-green-500"
                      : "text-prored-500") + " cursor-pointer"
                  }
                  key={index}
                  onClick={() => setActiveReservation(index)}
                >
                  {reserve.name}
                </span>
              ))}
            </h3>
          </div>
          <div className="h-full flex justify-between py-16">
            {/*  */}
            <div className="flex-1 flex flex-col text-xl gap-y-16">
              <div className="flex items-center justify-between">
                <span className="w-1/4">الاسم</span>
                <span className="w-3/4">
                  {reservation.length > 0 &&
                    reservation[activeReservation]?.name}
                </span>
              </div>

              <div className="flex items-center justify-between">
                <span className="w-1/4">البريد الإلكتروني</span>
                <span className="w-3/4">
                  {reservation.length > 0 &&
                    reservation[activeReservation]?.email}
                </span>
              </div>

              <div className="flex items-center justify-between">
                <span className="w-1/4">الرقم</span>
                <span className="w-3/4">
                  {reservation.length > 0 &&
                    reservation[activeReservation]?.phone}
                </span>
              </div>

              <div className="flex items-center justify-between">
                <span className="w-1/4">السيارة</span>
                <span className="w-3/4">
                  {reservation.length > 0 &&
                    reservation[activeReservation]?.carType}
                </span>
              </div>
            </div>

            {/* Line in the middle */}
            <div className="h-full w-[2px] bg-progray-200"></div>

            {/*  */}
            <div className="flex-1 flex flex-col text-xl gap-y-16 pr-20">
              <div className="flex items-center justify-between">
                <span className="w-1/4">المبلغ المدفوع</span>
                <span className="w-3/4">
                  {reservation[activeReservation]?.order?.amount} ر.س
                </span>
              </div>
              {reservation[activeReservation]?.order?.discountValue && (
                <div className="flex items-center justify-between">
                  <span className="w-1/4">قيمة الحسم المطبقة</span>
                  <span className="w-3/4">
                    {reservation[activeReservation]?.order?.discountValue} ر.س
                  </span>
                </div>
              )}
              <table className="">
                <tr>
                  <td className="pb-2 font-bold" colSpan={2}>
                    تفاصيل الطلب
                  </td>
                </tr>
                {reservation[activeReservation]?.order?.orderItems?.map(
                  (item, index) => (
                    <tr>
                      <td className="pt-6">
                        {JSON.parse(item.details).productName}
                      </td>
                      <td className="pt-6">{item.price} ر.س</td>
                      {JSON.parse(item.details).properties.map(
                        (prop, index) => (
                          <td className="pt-6">
                            {Object.entries(prop).map(([key, value]) => (
                              <div className="flex gap-x-4 items-center">
                                <span>{key === "color" ? "اللون" : ""}</span>
                                <div
                                  className="w-10 h-10 border-2 rounded-md border-progray-200"
                                  style={{ background: value }}
                                ></div>
                              </div>
                            ))}
                          </td>
                        )
                      )}
                    </tr>
                  )
                )}
              </table>
              {/* 
              <div className="flex items-center justify-between">
                <span className="w-1/4 underline text-green-500">
                  تغيير إلى تم
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

export default ShowReservation;
