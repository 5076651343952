import React from "react";
import Svg from "./../Svg";
import { useSignOut } from "react-auth-kit";
import { NavLink } from "react-router-dom";

const TopNav = ({ children }) => {
  const signOut = useSignOut();

  return (
    <div className="bg-progray-700 rounded-4xl w-full h-24 flex justify-between items-center px-14">
      <div>{children}</div>
      <div className="flex items-center gap-x-12">
        <NavLink
          to="/notification"
          className="flex items-center gap-x-4 text-propink-300 text-xl"
        >
          <Svg name="bell" />
          <span>الإشعارات</span>
        </NavLink>
        <div
          className="flex items-center gap-x-4 text-propink-300 text-xl cursor-pointer"
          onClick={() => signOut()}
        >
          <Svg name="logout" />
          <span>تسجيل الخروج</span>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
