import axios from "axios";
import UploadService from "./UploadService";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const CategoryService = {
  /**
   * Gets a list of categories
   *
   * @param {*} queryParameters
   * @returns
   */
  getAllCategories: async (queryParameters = {}) => {
    const response = await axios.get(
      `${API_BASE_URL}/categories${
        queryParameters.length > 0 ? `?${queryParameters}` : ""
      }`
    );
    return response.data;
  },

  /**
   *
   */
  createCategory: async (data, files) => {
    let images = [];

    for (const image of files) {
      const uploadedImage = await UploadService.uploadImage(image);
      images.push(uploadedImage.images[0]);
    }

    data.images = images;

    const response = await axios.post(`${API_BASE_URL}/categories`, data);
    return response.data;
  },

  /**
   *
   */
  updateCategory: async (id, data, files) => {
    let images = [];

    if (files?.length > 0) {
      for (const image of files) {
        const uploadedImage = await UploadService.uploadImage(image);
        images.push(uploadedImage.images[0]);
      }

      data.images = images;
    }

    const response = await axios.put(`${API_BASE_URL}/categories/${id}`, data);
    return response.data;
  },

  /**
   *
   */
  getCategoryById: async (id) => {
    return (await axios.get(`${API_BASE_URL}/categories/${id}`)).data;
  },

  /**
   *
   * @param {*} id
   */
  deleteCategory: async (id) => {
    return (await axios.delete(`${API_BASE_URL}/categories/${id}`)).data;
  },
};

axios.defaults.headers.common["Accept"] = "application/json";

export default CategoryService;
