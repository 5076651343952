import React from "react";
import Svg from "../Svg";
import { getApiBaseUrl } from "../../utils/filestream";
import { SocialService } from "../../services";

const Social = ({ social, index, deleteSocial }) => {
  return (
    <div className="flex items-center gap-x-8 w-full">
      <div className="text-xl">{index + 1}.</div>
      <div>
        <div className="flex gap-x-4 items-center cursor-pointer">
          <div className="text-xl underline w-16 h-16">
            <img
              src={`${getApiBaseUrl()}/${social.icon}`}
              alt=""
              className="object-cover"
            />
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="text-2xl">{social.url}</div>
      </div>
      <button type="button" onClick={deleteSocial}>
        <Svg name="trash" />
      </button>
    </div>
  );
};

export default Social;
