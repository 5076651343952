import { useForm } from "react-hook-form";
import Svg from "../Svg";

const Textarea = ({
  type = "text",
  placeholder = "",
  rows = 3,
  backgroundColor = "bg-progray-700",
  register,
  defaultValue = "",
}) => {
  return (
    <div className={"w-full border-b-2 border-progray-200 py-2"}>
      <textarea
        type={type}
        rows={rows}
        placeholder={placeholder}
        className={"w-full outline-none text-xl resize-none " + backgroundColor}
        {...register}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default Textarea;
