import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import TopNav from "./../../components/TopNav";
import ContentContainer from "./../../components/Containers/ContentContainer";
import Svg from "../../components/Svg";
import { ProductService } from "../../services";
import { generateUrlParameters, getPublicUrl } from "../../utils";
// import Pagination from "../../components/Pagination/Pagination";

const Product = () => {
  const [products, setProducts] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [orderType, setOrderType] = useState("");
  // Query is search as backend refers
  const [query, setQuery] = useState("");
  const [total, setTotal] = useState(1);

  useEffect(() => {
    const getAllProducts = async (queryParameters) => {
      try {
        const response = await ProductService.getAllProducts(queryParameters);
        setProducts(response?.products);
        setTotal(response.totalNumber);
      } catch (error) {
        if (process.env.REACT_APP_ENV === "development")
          console.error("Error fetching products:", error.message);
      }
    };

    getAllProducts(
      generateUrlParameters({
        query,
        orderBy,
        orderType,
      })
    );
  }, [query, orderBy, orderType]);

  const handleOrderByClick = (orderString) => {
    const [orderByFeild, orderTypeFeild] = orderString.split(":");

    setOrderBy(orderByFeild);
    setOrderType(orderTypeFeild);
  };

  // const pageChanged = (page) => { setValue('offset', page); if (onPageChange) onPageChange(page); };

  return (
    <>
      <TopNav />

      <ContentContainer>
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-x-4 border-b text-white">
            <Svg name="query" />
            <input
              type="text"
              placeholder="البحث عن المنتجات"
              className="bg-progray-700 outline-none text-xl py-2"
              onInput={(e) => setQuery(e.target.value)}
            />
          </div>
          <div className="relative">
            <div className="group">
              <div className="flex items-center gap-x-2 text-white text-xl">
                <Svg name="filter" />
                <span>فلترة</span>
              </div>
              <div className="z-10 absolute hidden group-hover:flex bg-progray-500 px-4 py-6 rounded-md text-xl w-60 top-0 left-0 flex-col gap-y-7 animate-fade-up">
                <div className="flex items-center gap-x-2 text-white text-xl">
                  <Svg name="filter" />
                  <span>فلترة</span>
                </div>
                <div
                  className="font-light"
                  onClick={() => handleOrderByClick("smallCarPrice:ASC")}
                >
                  الأسعار الأعلى
                </div>
                <hr />
                <div
                  className="font-light"
                  onClick={() => handleOrderByClick("smallCarPrice:DESC")}
                >
                  الأسعار الأدنى
                </div>
                <hr />
                <div
                  className="font-light"
                  onClick={() => handleOrderByClick("arName:ASC")}
                >
                  حسب الاسم (أ-ي)
                </div>
                <hr />
                <div
                  className="font-light"
                  onClick={() => handleOrderByClick("arName:DESC")}
                >
                  حسب الاسم (ي-أ)
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-5 mt-10 gap-y-3 gap-x-16">
          {/* Add Product Link */}
          <NavLink
            to="/product/create"
            className="flex flex-col w-full text-center"
          >
            <div className="flex items-center justify-center w-full aspect-square text-4xl rounded-4xl border-4 border-progray-200">
              <Svg name={"big-plus"} />
            </div>
            <h2 className="text-xl mt-6">إضافة منتج</h2>
          </NavLink>

          {/* Listing products */}
          {products.map((product, i) => (
            <div className="flex flex-col text-center w-full overflow-hidden">
              <NavLink
                to={"/product/create/" + product.id}
                className="relative w-full aspect-square rounded-4xl overflow-hidden group"
              >
                <img
                  src={
                    getPublicUrl() +
                    "/" +
                    (Array.isArray(product?.images) ? product.images[0] : "")
                  }
                  alt=""
                  className="bg-gray-500 w-full h-full object-cover hover:scale-110 transition-transform"
                />
              </NavLink>
              <h2 className="text-3xl mt-5">{product.arName}</h2>
              <p className="text-xl mt-6">{product.price}</p>
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center">
          {/* <Pagination pageSize={10} totalItems={total} onPageChange={pageChanged} currentPage={getValues('offset')} /> */}
        </div>
      </ContentContainer>
    </>
  );
};

export default Product;
